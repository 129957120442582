import { Injectable } from '@angular/core';
import { ModalComponent } from '@shared/components/modals/modal/modal.component';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	modals: ModalComponent[] = [];

	constructor() {}

	add(modal: any) {
		this.modals.push(modal);
	}

	remove(id: string) {
		this.modals = this.modals.filter((modal) => modal.id !== id);
	}

	open(id: string, data?: {}) {
		const modal = this.modals.filter((modal) => modal.id === id)[0];
		const modalData = {
			track: null,
			playlist: null,
			bucket: null,
			actions: null,
			...data
		};
		modal.data.next(modalData);
		modal && modal.modalElement.first.nativeElement.classList.add('show');
	}

	getModalData(id: string) {
		const modal = this.modals.filter((modal) => modal.id === id)[0];
		return modal.data;
	}

	close(id: string) {
		const modal = this.modals.filter((modal) => modal.id === id)[0];
		modal &&
        modal.modalElement.first.nativeElement.classList.remove('show');
	}

	closeAll() {
		this.modals.forEach((modal) => {
			modal.modalElement.first.nativeElement.classList.remove('show');
		});
	}
}
